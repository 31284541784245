import React from 'react';

const Loading = ({backgroundColor = 'rgba(255, 255, 255, 0.6)'}) => (
  <div
    className="loading"
    style={{
      zIndex: 9,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: backgroundColor,
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        color: '#15212f',
      }}
    >
      <div
        style={{
          borderWidth: '3px',
        }}
        className="spinner-border"
        role="status"
      ></div>
      <strong style={{marginLeft: '10px'}}>Loading...</strong>
    </div>
  </div>
);

export default Loading;
