import React from 'react';

const SVG = ({width = '20px', height = '100%'}) => (
  <svg
    width={width}
    height={height}
    viewBox="-1 -1 20 20"
    className="bi bi-block"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Block</title>
    <g id="Symbols" fillRule="evenodd" strokeWidth="1px">
      <g
        id="icon/general/block"
        transform="translate(-1.000000, -1.000000)"
        fillRule="nonzero"
      >
        <path
          d="M10,1 C12.3869484,1 14.6761336,1.94821156 16.363961,3.63603897 C18.0517884,5.32386638 19,7.61305159 19,10 C19,14.9705627 14.9705627,19 10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 Z M15.4776711,5.18295319 L15.4235372,5.27331117 C15.4004608,5.30769559 15.3749944,5.34102931 15.3471228,5.37309197 L6.16229638,15.9390261 C6.11143781,15.9975322 6.05527359,16.0486963 5.9951425,16.0924248 C7.16057474,16.8531427 8.54547966,17.2815954 10,17.2780223 C14.0126994,17.261561 17.261561,14.0126994 17.2780223,10 C17.2824652,8.19137958 16.618926,6.49039592 15.4776711,5.18295319 Z M12.782383,3.24484211 C10.0544264,2.11094587 6.91192634,2.7340158 4.82297107,4.82297107 C2.7340158,6.91192634 2.11094587,10.0544264 3.24484211,12.782383 C3.56052913,13.541871 3.99505275,14.227489 4.52152506,14.8213435 C4.55626409,14.7524241 4.60028921,14.6874036 4.65287722,14.626908 L13.8377036,4.06097391 C13.8908609,3.99982347 13.9498142,3.94669372 14.0130351,3.90169153 C13.6303853,3.6466447 13.2185666,3.42614517 12.782383,3.24484211 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default SVG;
