import React from 'react';

import Basket from '../icons-af/basket';
import Block from '../icons-af/block';
import Heart from '../icons-af/heart';
import Ribbon from '../icons-af/ribbon';

import useTranslation from '../../hooks/use-translation/use-translation';

const icons = {
  basket: Basket,
  block: Block,
  heart: Heart,
  ribbon: Ribbon,
};

const Button = ({
  secondary = false,
  tertiary = false,
  title,
  icon,
  active = false,
  disabled = false,
  submit = false,
  wide = false,
  href,
  target,
  dataDismiss,
  onClick,
  children,
}: buttonProps) => {
  const {t} = useTranslation();

  const btnClass = () => {
    const sharedCls = `${wide ? ' btn-block' : ''}`;

    if (icon) {
      return `btn-action${!children ? ' btn-icon-only' : ''}${sharedCls}${
        disabled ? ' disabled' : ''
      }${active ? ' active' : ''}`;
    } else {
      return `btn-cta btn-${
        tertiary ? 'tertiary' : secondary ? 'secondary' : 'primary'
      }${sharedCls}${disabled ? ' disabled' : ''}`;
    }
  };

  const btnIcon = (iconName) => {
    const SVG = icons[iconName];
    return <SVG height={`${children ? '19px' : '20px'}`} />;
  };

  const btnTitle = () => {
    if (title) return title;

    if (Array.isArray(children)) return children.join('');

    return children;
  };

  return href ? (
    <a
      href={href}
      target={target}
      role="button"
      className={btnClass()}
      title={t(btnTitle())}
      aria-disabled={disabled}
    >
      {icon && btnIcon(icon)}
      {t(children)}
    </a>
  ) : (
    <button
      type={submit ? 'submit' : 'button'}
      title={t(btnTitle())}
      className={btnClass()}
      onClick={onClick}
      aria-disabled={disabled}
      data-dismiss={dataDismiss}
    >
      {icon && btnIcon(icon)}
      {t(children)}
    </button>
  );
};

interface sharedProps {
  disabled?: boolean;
  submit?: boolean;
  dataDismiss?: string;
  onClick?: (e?) => any;
  title?: string;
  wide?: boolean;
  children?: any;
}

interface actionProps extends sharedProps {
  active?: boolean;
  icon: 'block' | 'heart' | 'ribbon' | 'basket';
}

interface ctaProps extends sharedProps {
  secondary?: boolean;
  tertiary?: boolean;
}

interface linkProps {
  href: string;
  target?: '_blank';
  secondary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  active?: boolean;
  icon?: 'block' | 'heart' | 'ribbon' | 'basket';
  title?: string;
  wide?: boolean;
  children?: any;
}

type UnionKeys<T> = T extends T ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;

type StrictUnion<T> = StrictUnionHelper<T, T>;

export type buttonProps = StrictUnion<actionProps | ctaProps | linkProps>;

export default Button;
