const dropdownFactory = ($, _, document, window, KEYS) => {
  // const min0 = (x) => x < 0 ? 0 : x;
  // const y = (y, h, wh) => {
  //   const absH = y + h;
  //   return absH > wh ? min0(y - (absH - wh)) : y;
  // }

  /**
   * Works out the number of pixels hidden outside the viewport.
   * Also works horizontally if the params are x, w and vw.
   *
   * @param {number} y - Top position(relative to the viewport)
   *                     of the el that is to be worked out.
   * @param {number} h - Height of the el that is to be worked out.
   * @param {number} vh - Height of the viewport(window.innerHeight).
   * @return {number} Nr. of px. hidden outside the top(negative)
   *                  or bottom(positive) of the viewport.
   *
   * @example
   *
   *     y(-10, 50, 100);  //=> -10
   *     y(60, 50, 100);  //=> 10
   *     y(70, 50, 100);  //=> 20
   *     y(80, 50, 100);  //=> 30
   *
   *     y(40, 50, 100);  //=> 0
   *     y(50, 50, 100);  //=> 0
   *
   *     y(50, 90, 100);  //=> 40
   *     y(50, 100, 100);  //=> 50
   *     y(50, 110, 100);  //=> 50
   */
  const y = (y, h, vh) => {
    if (y < 0) {
      return y;
    }
    const maxY = (x) => (x > y ? y : x);
    const absH = y + h;
    return absH > vh ? maxY(absH - vh) : 0;
  };

  const doWhenClickingOutside = (callback, elements) => {
    const outsideClickHandler = (e) => {
      if (elements.every((el) => !el.contains(e.target))) {
        callback();
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      $.off('click', outsideClickHandler, document);
    };

    $.on('click', outsideClickHandler, document);
  };

  const CLASSNAME = {
    BODY_SCROLL_LOCK: 'dropdown-open',
    SHOW: 'show',
  };

  const _default = {
    offset: 0,
    constrainwidth: false,
    beloworigin: true,
    hover: false,
    alignment: 'left',
    autoClose: true,
  };

  return (button, content, options) => {
    const opts = _.merge(options, _default);
    let scrollYOffset = 0;
    let scrollXOffset = 0;

    const isOpen = () => $.getAttribute('aria-expanded', button) === 'true';

    const getCoordinates = () => {
      let offsetTop = $.offsetTop(button);
      const offsetLeft = $.offsetLeft(button);

      // beloworigin
      if (opts.beloworigin) {
        offsetTop += $.outerHeight(button);
      }

      // Check for scrolling positioned container.
      const p = $.parent(button);
      if (p.tagName !== 'BODY') {
        if ($.hasVerticalScrollbar(p)) {
          scrollYOffset = $.scrollTop(p);
        }
        if ($.hasHorizontalScrollbar(p)) {
          scrollXOffset = $.scrollLeft(p);
        }
      }

      return {
        y: opts.offset + offsetTop - scrollYOffset,
        x: offsetLeft - scrollXOffset,
      };
    };

    const open = () => {
      content.style.minWidth = $.outerWidth(button) + 'px'; // ?
      if (opts.constrainwidth) {
        content.style.width = $.outerWidth(button) + 'px';
      }

      const coordinates = getCoordinates();

      content.style.top = coordinates.y + 'px';
      content.style.left = coordinates.x + 'px';

      $.setAttribute('aria-expanded', 'true', button);
      $.addClass(CLASSNAME.SHOW, content);

      // adjust position
      if (opts.alignment === 'right') {
        const rightOfButton = $.offsetLeft(button) + $.outerWidth(button);
        coordinates.x = rightOfButton - $.outerWidth(content) - scrollXOffset;
        content.style.left = coordinates.x + 'px';
      }

      // reposition the content to be visible within the viewport
      const b = content.getBoundingClientRect();
      content.style.top = coordinates.y - y(b.top, $.outerHeight(content), window.innerHeight) + 'px';
      content.style.left = coordinates.x - y(b.left, $.outerWidth(content), window.innerWidth) + 'px';

      setTimeout(() => {
        doWhenClickingOutside(closeIfOpen, opts.autoClose ? [button] : [button, content]);
      }, 0);
    };

    const close = () => {
      $.removeClass(CLASSNAME.SHOW, content);
      $.removeAttribute('aria-expanded', button);
    };

    const toggle = () => {
      if (isOpen()) {
        close();
      } else {
        open();
      }
    };

    let timeoutID = null;

    const onBlur = () => {
      // delay the closing in case a descendant gains the focus
      timeoutID = setTimeout(close, 0);
    };

    const onFocus = () => {
      // cancel the previous blur/closing-intention
      clearTimeout(timeoutID);
    };

    const onKeydown = (e) => {
      if (e.which === KEYS.ESC) {
        if (isOpen()) {
          close();
          e.preventDefault();
        }
        return;
      }

      if (e.which === KEYS.ENTER) {
        if (e.target === button) {
          toggle();
          e.preventDefault();
        }
      }
    };

    const openIfClosed = () => {
      if (!isOpen()) {
        open();
      }
    };

    const closeIfOpen = () => {
      if (isOpen()) {
        close();
      }
    };

    const bindEvents = (fn) => {
      if (opts.hover) {
        // button
        fn('mouseenter', openIfClosed, button); // open on mouse enter
        fn('mouseenter', onFocus, button); // cancel the previous blur/closing-intention
        fn('mouseleave', onBlur, button);
        fn('focus', openIfClosed, button, {capture: true}); // open on focus
        fn('blur', onBlur, button, {capture: true});
        fn('focus', onFocus, button, {capture: true});

        // content
        fn('mouseenter', onFocus, content);
        fn('mouseleave', onBlur, content);
        fn('blur', onBlur, content, {capture: true});
        fn('focus', onFocus, content, {capture: true});
      } else {
        fn('click', open, button);
        fn('keydown', onKeydown, document, {capture: true});
      }
    };

    const destroy = () => {
      closeIfOpen();
      bindEvents($.off);
    };

    const init = () => {
      bindEvents($.on);
    };

    init();

    return {
      open: openIfClosed,
      close: closeIfOpen,
      destroy: destroy,
    };
  };
};

export default dropdownFactory;
