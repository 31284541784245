import React from 'react';

const pointing = {
  '0': 'down',
  '90': 'left',
  '180': 'up',
  '270': 'right',
};

const SVG = ({width = '16px', height = '16px', rotate = 0}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 198.4 198.4"
    className="bi bi-caret"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{`Caret ${pointing[rotate]}`}</title>
    <g transform={`rotate(${rotate}, 99.2, 99.2)`}>
      <path fillRule="evenodd" d="M27.9,63.6h142.6l-71.3,71.3L27.9,63.6z" />
    </g>
  </svg>
);

export default SVG;
