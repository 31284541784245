import _ from '../utils/_';

const translationFactory = () => {
  const replaceBracketedVariables = (template, data) =>
    template.replace(/{\s?(.*?)\s?}/g, (_, group) => data[group] || '...');

  const t = (x, context) => {
    if (_.undef(context)) {
      return x;
    }

    return replaceBracketedVariables(x, context);
  };

  return {
    t,
  };
};

export default translationFactory;
