import React from 'react';

const SVG = ({width = '25px', height = '100%'}) => (
  <svg
    width={width}
    height={height}
    viewBox="45 45 110 120"
    className="bi bi-basket3-fill"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Basket</title>
    <g>
      <path d="M150.7,75.6h-6.2l-27.9-27.7c-2.5-2.5-6.6-2.5-9.1,0c-2.5,2.5-2.5,6.6,0,9.1l18.7,18.6H71.8l19.1-19.1c2.5-2.5,2.5-6.6,0-9.1c-2.5-2.5-6.6-2.5-9.1,0L53.5,75.6h-5.8H34.8c-3.6,0-6.4,2.9-6.4,6.4s2.9,6.4,6.4,6.4h7.6l11.8,59.2c0.6,3,3.2,5.2,6.3,5.2l77.3,0c0,0,0,0,0,0c3.1,0,5.7-2.2,6.3-5.2L156,88.5h7.6c3.6,0,6.4-2.9,6.4-6.4s-2.9-6.4-6.4-6.4L150.7,75.6L150.7,75.6z" />
    </g>
  </svg>
);

export default SVG;
