import React from 'react';

const styleMap = {
  p1: 'body1',
  p2: 'body2',
  p3: 'body3',
  'p1-b': 'subtitle1',
  'p2-b': 'subtitle2',
  'p3-b': 'subtitle3',
  hero: 'header-hero',
  main: 'header-main',
  section: 'header-section',
  title: 'header-title',
  art: 'header-art',
  highlights: 'header-highlights',
};

const cls = (style, color, truncate, align) => {
  return `${styleMap[style]}${color ? ' ' + color : ''}${truncate ? ' truncate' : ''}${
    align ? ' ' + align : ''
  }`;
};

const H1 = ({style, color, truncate, align, children}: hProps) => {
  return <h1 className={cls(style, color, truncate, align)}>{children}</h1>;
};

const H2 = ({style, color, truncate, align, children}: hProps) => {
  return <h2 className={cls(style, color, truncate, align)}>{children}</h2>;
};

const H3 = ({style, color, truncate, align, children}: hProps) => {
  return <h3 className={cls(style, color, truncate, align)}>{children}</h3>;
};

const H4 = ({style, color, truncate, align, children}: hProps) => {
  return <h4 className={cls(style, color, truncate, align)}>{children}</h4>;
};

const H5 = ({style, color, truncate, align, children}: hProps) => {
  return <h5 className={cls(style, color, truncate, align)}>{children}</h5>;
};

const H6 = ({style, color, truncate, align, children}: hProps) => {
  return <h6 className={cls(style, color, truncate, align)}>{children}</h6>;
};

const P = ({style, color, truncate, align, children}: pProps) => {
  return <p className={cls(style, color, truncate, align)}>{children}</p>;
};

export interface hProps {
  style:
    | 'hero'
    | 'main'
    | 'section'
    | 'title'
    | 'art'
    | 'highlights'
    | 'p1'
    | 'p2'
    | 'p3'
    | 'p1-b'
    | 'p2-b'
    | 'p3-b';
  align?: 'left' | 'center' | 'right';
  color?: 'accent' | 'secondary' | 'white';
  truncate?: boolean;
  children: any;
}

export interface pProps {
  style: 'p1' | 'p2' | 'p3' | 'p1-b' | 'p2-b' | 'p3-b';
  align?: 'left' | 'center' | 'right';
  color?: 'accent' | 'secondary' | 'white';
  truncate?: boolean;
  children?: any;
}

export {H1, H2, H3, H4, H5, H6, P};
