import React from 'react';

const Heart = ({width = '25px', height = '100%'}) => (
  <svg
    width={width}
    height={height}
    viewBox="32 30 128 128"
    className="bi bi-heart"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Heart</title>
    <g>
      <path
        fillRule="evenodd"
        d="M134.9,45.7c-19.7,0-35.7,16-35.7,35.7c0-19.7-16-35.7-35.7-35.7s-35.7,16-35.7,35.7c0,39.4,31.9,71.3,71.3,71.3s71.3-31.9,71.3-71.3C170.5,61.7,154.5,45.7,134.9,45.7z"
      />
    </g>
  </svg>
);

export default Heart;
