import React from 'react';

const SVG = ({width = '20px', height = '100%'}) => (
  <svg
    width={width}
    height={height}
    viewBox="28 32 140 140"
    className="bi bi-ribbon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ribbon</title>
    <g>
      <polygon
        fillRule="evenodd"
        points="59.2,45 59.2,153.4 99.2,113.3 139.3,153.4 139.3,45"
      />
    </g>
  </svg>
);

export default SVG;
